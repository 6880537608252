<template>
    <div>
        <el-row>
            <el-col>
                <p class="body-small-semi-bold">Switch</p>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-switch
                    v-model="value2"
                    active-color="#1570EF"
                    inactive-color="#F2F4F7"
                >
                </el-switch>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-switch
                    v-model="value2"
                    active-color="#1570EF"
                    inactive-color="#F2F4F7"
                    active-text="Label"
                >
                </el-switch>
            </el-col>
        </el-row>

        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <p class="body-small-semi-bold">Switch disabled</p>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-switch
                    disabled
                    v-model="value2"
                    active-color="#1570EF"
                    inactive-color="#F2F4F7"
                >
                </el-switch>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-switch
                    disabled
                    v-model="value2"
                    active-color="#1570EF"
                    inactive-color="#F2F4F7"
                    active-text="Label"
                >
                </el-switch>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            value2: 1,
        };
    },
};
</script>
<style lang="scss" scoped>
.el-row {
    margin: 24px 12px;
    display: flex;
    align-items: center;
}
</style>
<style lang="scss">
.el-switch {
    .el-switch__core {
        border-radius: 12px;
        &:active {
            background-color: var(--neutral-300) !important;
        }
    }
    &:focus {
        background: var(--neutral-300);
    }
}

.el-switch.is-checked {
    .el-switch__core {
        &:active {
            background-color: var(--primary-600) !important;
        }
    }
}

.el-switch.is-disabled {
    border-color: var(--primary-200);
    .el-switch__label {
        color: var(--neutral-300);
    }
}

.el-switch__label--right,
.el-switch__label.is-active {
    color: var(--Neutral-600);
}

.el-switch__core:after {
    fill: var(--Neutral-0, #fff);
    filter: drop-shadow(0px 1px 3px rgba(166, 175, 195, 0.4));
}
</style>
